import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import Price, { PriceProps } from 'src/components/Price/Price';
import { colors } from 'src/theme/colors';
import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import { device } from 'src/theme/breakpoints';
import FeaturesList from 'src/components/FeaturesList';
import { FeatureProps } from 'src/components/FeaturesList/FeaturesList';

const Block = styled.div`
  background-color: ${colors.white};
  color: ${colors.blue700};
  margin-top: 40px;
  padding: 20px 16px 30px;

  @media ${device.laptopSUP} {
    padding: 68px 52px 84px;
  }

  @media ${device.desktop} {
    margin-top: 48px;
    padding: 82px 62px 84px;
  }
`;

const BundleNumber = styled.span`
  align-items: center;
  background-color: ${colors.blue200};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 20px;
  justify-content: center;
  height: 42px;
  margin-right: 18px;
  width: 42px;

  @media ${device.desktop} {
    height: 52px;
    width: 52px;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
`;

const StyledHeading = styled(Heading)`
  margin: 0;
`;

const Body = styled.div<{ hasAside?: boolean }>`
  margin-top: 10px;

  @media ${device.laptopSUP} {
    padding-left: 62px;
  }

  @media ${device.desktop} {
    margin-top: 18px;
  }

  ${({ hasAside }) =>
    hasAside &&
    css`
      display: grid;

      @media ${device.laptopSUP} {
        column-gap: 40px;
        grid-template-columns: 1fr 272px;
      }
    `}
`;

const Text = styled.p`
  margin: 0 0 36px;

  @media ${device.desktop} {
    font-size: 21px;
  }
`;

const PriceWrapper = styled.div<{ hasAside?: boolean }>`
  display: flex;

  ${({ hasAside }) =>
    hasAside &&
    css`
      flex-direction: column;

      @media ${device.tabletDOWN} {
        border-top: 1px solid ${colors.grey200};
        margin-top: 20px;
        padding-top: 20px;
      }

      @media ${device.laptopSUP} {
        border-left: 1px solid ${colors.grey200};
        padding-left: 40px;
      }
    `}

  ${({ hasAside }) =>
    !hasAside &&
    css`
      align-items: center;

      & > :not(:last-child) {
        border-right: 1px solid ${colors.grey200};
        margin-right: 48px;
        padding-right: 72px;
      }
    `}
`;

const PriceFooter = styled.p`
  color: ${colors.grey400};
  margin: 40px 0 0;
`;

const MiddlePrice = styled.div`
  margin: 20px 0;

  @media ${device.desktop} {
    margin: 40px 0;
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;

  & > :not(:first-child) {
    border-left: 1px solid ${colors.grey200};
    margin-left: 96px;
    padding-left: 96px;
    height: 100%;
  }
`;

const Footer = styled.div`
  @media ${device.laptopSUP} {
    padding-left: 62px;
  }
`;

const TitleLogo = styled.div`
  margin-right: 16px;

  & > .gatsby-image-wrapper {
    height: 48px;
  }

  & img {
    max-height: 100%;
    width: auto;
  }
`;

export interface SubmoduleProps {
  title?: string;
  text?: string;
  moduleNumber?: number | string;
  moduleTitle?: string;
  priceTop?: PriceProps;
  priceMiddle?: PriceProps;
  priceBottom?: PriceProps;
  priceFooterText?: string;
  features?: FeatureProps[];
  number?: string | number;
  hasAside?: boolean;
  footer?: React.ReactNode;
  titleLogo?: React.ReactNode;
}

const Submodule: FC<SubmoduleProps> = ({
  title,
  text,
  moduleNumber,
  moduleTitle,
  priceTop,
  priceMiddle,
  priceBottom,
  priceFooterText,
  features,
  number,
  hasAside,
  footer,
  titleLogo,
}) => (
  <Block>
    <HeadingScope>
      <Header>
        <BundleNumber>{number}</BundleNumber>
        {titleLogo && <TitleLogo>{titleLogo}</TitleLogo>}
        {title && (
          <StyledHeading variant={HeadingVariant.ECHO}>{title}</StyledHeading>
        )}
      </Header>
      <Body hasAside={hasAside}>
        <div>
          {text && <Text>{text} </Text>}
          <FeaturesWrapper>
            {features &&
              features.map((list, i) => (
                <FeaturesList
                  key={i}
                  features={list}
                  moduleNumber={moduleNumber}
                  moduleTitle={moduleTitle}
                  columns={hasAside ? 2 : 1}
                />
              ))}
          </FeaturesWrapper>
        </div>
        {(priceBottom || priceMiddle || priceTop) && (
          <PriceWrapper hasAside={hasAside}>
            {priceTop && (
              <Price
                bottomText={priceTop.bottomText}
                price={priceTop.price}
                topText={priceTop.topText}
              />
            )}
            {priceMiddle && (
              <MiddlePrice>
                <Price
                  bottomText={priceMiddle.bottomText}
                  price={priceMiddle.price}
                />
              </MiddlePrice>
            )}
            {priceBottom && (
              <Price
                bottomText={priceBottom.bottomText}
                price={priceBottom.price}
              />
            )}
            {priceFooterText && <PriceFooter>{priceFooterText}</PriceFooter>}
          </PriceWrapper>
        )}
      </Body>
      {footer && <Footer>{footer}</Footer>}
    </HeadingScope>
  </Block>
);

export default Submodule;
