import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  color: ${colors.grey400};
  font-size: 14px;
  margin: 0;

  @media ${device.desktop} {
    font-size: 18px;
  }
`;

const PriceWrapper = styled.div`
  align-items: flex-end;
  color: ${colors.purple400};
  display: flex;
  font-size: 22px;
  line-height: 1;
  margin: 8px 0 0;

  @media ${device.desktop} {
    font-size: 40px;
  }
`;

const StyledPrice = styled.span<Pick<PriceProps, 'main'>>`
  font-size: ${props => (props.main ? '60px' : '18px')};
  font-weight: 700;
  margin-right: 16px;

  @media ${device.desktop} {
    font-size: ${props => (props.main ? '80px' : '18px')};
  }
`;
export interface PriceProps {
  bottomText?: string;
  main?: boolean;
  price: string;
  topText?: string;
  className?: string;
}

const Price: FC<PriceProps> = ({
  bottomText,
  className,
  main,
  price,
  topText,
}) => (
  <Wrapper>
    {topText && <Text>{topText}</Text>}
    <PriceWrapper className={className}>
      <StyledPrice main={main}>{price}</StyledPrice>
    </PriceWrapper>
    {bottomText && <Text>{bottomText}</Text>}
  </Wrapper>
);

export default Price;
