import React, { FC } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import { device } from 'src/theme/breakpoints';
import Content from 'src/components/Content';
import { colors } from 'src/theme/colors';
import Module from 'src/components/Module';

const GreyBackground = styled.div`
  background-color: ${colors.grey100};
  margin-top: -80px;
  padding: 40px 0 60px;

  @media ${device.laptopUP} {
    margin-top: -120px;
    padding: 140px 0;
  }
`;

const LogosWrapper = styled.div`
  align-items: center;
  column-gap: 46px;
  display: flex;

  & > .gatsby-image-wrapper {
    max-width: 120px;
    max-height: 48px;
  }
`;

const ProductsPage: FC = () => {
  const { t } = useTranslation();

  const modules = [
    {
      id: 'ecommerce',
      title: t('products_module_one_title'),
      nextSolutionUrl: '#data-management',
      text: t('products_module_one_text'),
      submodules: [
        {
          title: t('products_module_one_sub_one_title'),
          features: [
            {
              title: 'Standard',
              price: '9 € / month',
              priceBottomText: t('common_if_paid_quarter'),
              listItems: [
                {
                  title: t('products_module_one_sub_one_list_item_1'),
                  text: t('products_module_one_sub_one_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_2'),
                  text: t('products_module_one_sub_one_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_3'),
                  text: t('products_module_one_sub_one_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_4'),
                  text: t('products_module_one_sub_one_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_5'),
                  text: t('products_module_one_sub_one_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_6'),
                  text: t('products_module_one_sub_one_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_7'),
                  text: t('products_module_one_sub_one_list_item_7_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_8'),
                  text: t('products_module_one_sub_one_list_item_8_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_9'),
                  text: t('products_module_one_sub_one_list_item_9_text'),
                },
                {
                  title: t('products_module_one_sub_one_list_item_10'),
                  text: t('products_module_one_sub_one_list_item_10_text'),
                },
              ],
            },
            {
              title: 'Premium',
              price: '39 € / month',
              isPremium: true,
              priceBottomText: t('common_if_paid_quarter'),
              listItems: [
                {
                  title: t('products_module_one_sub_one_premium_list_item_1'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_1_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_2'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_2_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_3'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_3_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_4'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_4_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_5'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_5_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_6'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_6_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_7'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_7_text'
                  ),
                },
                {
                  title: t('products_module_one_sub_one_premium_list_item_8'),
                  text: t(
                    'products_module_one_sub_one_premium_list_item_8_text'
                  ),
                },
              ],
            },
          ],
        },
        {
          title: t('products_module_one_sub_two_title'),
          text: t('products_module_one_sub_two_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_one_sub_two_list_item_1'),
                  text: t('products_module_one_sub_two_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_2'),
                  text: t('products_module_one_sub_two_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_3'),
                  text: t('products_module_one_sub_two_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_4'),
                  text: t('products_module_one_sub_two_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_5'),
                  text: t('products_module_one_sub_two_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_6'),
                  text: t('products_module_one_sub_two_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_7'),
                  text: t('products_module_one_sub_two_list_item_7_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_8'),
                  text: t('products_module_one_sub_two_list_item_8_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_9'),
                  text: t('products_module_one_sub_two_list_item_9_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_10'),
                  text: t('products_module_one_sub_two_list_item_10_text'),
                },
                {
                  title: t('products_module_one_sub_two_list_item_11'),
                  text: t('products_module_one_sub_two_list_item_11_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('products_module_one_sub_if_ordered'),
          },
        },
        {
          title: t('products_module_one_sub_three_title'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_one_sub_three_list_item_1'),
                  text: t('products_module_one_sub_three_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_2'),
                  text: t('products_module_one_sub_three_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_3'),
                  text: t('products_module_one_sub_three_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_4'),
                  text: t('products_module_one_sub_three_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_5'),
                  text: t('products_module_one_sub_three_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_6'),
                  text: t('products_module_one_sub_three_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_three_list_item_7'),
                  text: t('products_module_one_sub_three_list_item_7_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('products_module_one_sub_if_ordered'),
          },
        },
        {
          title: t('products_module_one_sub_four_title'),
          text: t('products_module_one_sub_four_text'),
          features: [
            {
              title: t('common_standard_package'),
              price: t('common_included'),
              priceBottomText: t('products_module_one_sub_if_ordered'),
              listItems: [
                {
                  title: t('products_module_one_sub_four_list_item_1'),
                  text: t('products_module_one_sub_four_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_2'),
                  text: t('products_module_one_sub_four_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_3'),
                  text: t('products_module_one_sub_four_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_4'),
                  text: t('products_module_one_sub_four_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_5'),
                  text: t('products_module_one_sub_four_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_6'),
                  text: t('products_module_one_sub_four_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_four_list_item_7'),
                  text: t('products_module_one_sub_four_list_item_7_text'),
                },
              ],
            },
            {
              title: t('common_premium_package'),
              price: '49 € / month',
              priceBottomText: t('common_if_paid_quarter'),
              isPremium: true,
              listItems: [
                {
                  title: t('products_module_one_sub_five_list_item_1'),
                  text: t('products_module_one_sub_five_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_five_list_item_2'),
                  text: t('products_module_one_sub_five_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_five_list_item_3'),
                  text: t('products_module_one_sub_five_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_five_list_item_4'),
                  text: t('products_module_one_sub_five_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_five_list_item_5'),
                  text: t('products_module_one_sub_five_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_five_list_item_6'),
                  text: t('products_module_one_sub_five_list_item_6_text'),
                },
              ],
            },
          ],
        },
        {
          title: t('products_module_one_sub_six_title'),
          text: t('products_module_one_sub_six_text'),
          features: [
            {
              title: t('common_standard_package'),
              price: t('common_included'),
              priceBottomText: t('products_module_one_sub_if_ordered'),
              listItems: [
                {
                  title: t('products_module_one_sub_six_list_item_1'),
                  text: t('products_module_one_sub_six_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_2'),
                  text: t('products_module_one_sub_six_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_3'),
                  text: t('products_module_one_sub_six_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_4'),
                  text: t('products_module_one_sub_six_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_5'),
                  text: t('products_module_one_sub_six_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_6'),
                  text: t('products_module_one_sub_six_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_7'),
                  text: t('products_module_one_sub_six_list_item_7_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_8'),
                  text: t('products_module_one_sub_six_list_item_8_text'),
                },
                {
                  title: t('products_module_one_sub_six_list_item_9'),
                  text: t('products_module_one_sub_six_list_item_9_text'),
                },
              ],
            },
            {
              title: t('common_premium_package'),
              price: '99 € / month',
              priceBottomText: t('common_if_paid_quarter'),
              listItems: [
                {
                  title: t('products_module_one_sub_seven_list_item_1'),
                  text: t('products_module_one_sub_seven_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_2'),
                  text: t('products_module_one_sub_seven_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_3'),
                  text: t('products_module_one_sub_seven_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_4'),
                  text: t('products_module_one_sub_seven_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_5'),
                  text: t('products_module_one_sub_seven_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_7'),
                  text: t('products_module_one_sub_seven_list_item_7_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_8'),
                  text: t('products_module_one_sub_seven_list_item_8_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_9'),
                  text: t('products_module_one_sub_seven_list_item_9_text'),
                },
                {
                  title: t('products_module_one_sub_seven_list_item_10'),
                  text: t('products_module_one_sub_seven_list_item_10_text'),
                },
              ],
            },
          ],
        },
        {
          title: t('products_module_one_sub_eigth_title'),
          text: t('products_module_one_sub_eigth_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_one_sub_eigth_list_item_1'),
                  text: t('products_module_one_sub_eigth_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_2'),
                  text: t('products_module_one_sub_eigth_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_3'),
                  text: t('products_module_one_sub_eigth_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_4'),
                  text: t('products_module_one_sub_eigth_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_5'),
                  text: t('products_module_one_sub_eigth_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_6'),
                  text: t('products_module_one_sub_eigth_list_item_6_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_7'),
                  text: t('products_module_one_sub_eigth_list_item_7_text'),
                },
                {
                  title: t('products_module_one_sub_eigth_list_item_8'),
                  text: t('products_module_one_sub_eigth_list_item_8_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('products_module_one_sub_if_ordered'),
          },
        },
        {
          title: t('products_module_one_sub_nine_title'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_one_sub_nine_list_item_1'),
                  text: t('products_module_one_sub_nine_list_item_1_text'),
                },
                {
                  title: t('products_module_one_sub_nine_list_item_2'),
                  text: t('products_module_one_sub_nine_list_item_2_text'),
                },
                {
                  title: t('products_module_one_sub_nine_list_item_3'),
                  text: t('products_module_one_sub_nine_list_item_3_text'),
                },
                {
                  title: t('products_module_one_sub_nine_list_item_4'),
                  text: t('products_module_one_sub_nine_list_item_4_text'),
                },
                {
                  title: t('products_module_one_sub_nine_list_item_5'),
                  text: t('products_module_one_sub_nine_list_item_5_text'),
                },
                {
                  title: t('products_module_one_sub_nine_list_item_6'),
                  text: t('products_module_one_sub_nine_list_item_6_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('products_module_one_sub_if_ordered'),
          },
        },
      ],
    },
    {
      id: 'data-management',
      title: t('products_module_two_title'),
      prevSolutionUrl: '#ecommerce',
      nextSolutionUrl: '#integrations',
      submodules: [
        {
          title: t('products_module_two_sub_one_title'),
          text: t('products_module_two_sub_one_text'),
          hasAside: true,
          features: [
            {
              title: t('common_standard_package'),
              listItems: [
                {
                  title: t('products_module_two_sub_one_list_item_1'),
                  text: t('products_module_two_sub_one_list_item_1_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_2'),
                  text: t('products_module_two_sub_one_list_item_2_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_3'),
                  text: t('products_module_two_sub_one_list_item_3_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_4'),
                  text: t('products_module_two_sub_one_list_item_4_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_5'),
                  text: t('products_module_two_sub_one_list_item_5_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_6'),
                  text: t('products_module_two_sub_one_list_item_6_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_7'),
                  text: t('products_module_two_sub_one_list_item_7_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_8'),
                  text: t('products_module_two_sub_one_list_item_8_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_9'),
                  text: t('products_module_two_sub_one_list_item_9_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_10'),
                  text: t('products_module_two_sub_one_list_item_10_text'),
                },
                {
                  title: t('products_module_two_sub_one_list_item_11'),
                  text: t('products_module_two_sub_one_list_item_11_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('products_module_one_sub_if_ordered'),
          },
        },
        {
          title: t('products_module_two_sub_two_title'),
          text: t('products_module_two_sub_two_text'),
          features: [
            {
              listItems: [
                {
                  title: t('products_module_two_sub_two_list_item_1'),
                  text: t('products_module_two_sub_two_list_item_1_text'),
                },
                {
                  title: t('products_module_two_sub_two_list_item_2'),
                  text: t('products_module_two_sub_two_list_item_2_text'),
                },
                {
                  title: t('products_module_two_sub_two_list_item_3'),
                  text: t('products_module_two_sub_two_list_item_3_text'),
                },
                {
                  title: t('products_module_two_sub_two_list_item_4'),
                  text: t('products_module_two_sub_two_list_item_4_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: '49 € / month',
            bottomText: t('common_if_up_to_10_products'),
          },
          priceMiddle: {
            price: '99 € / month',
            bottomText: t('common_if_up_to_100_products'),
          },
          priceBottom: {
            price: '249 € / month',
            bottomText: t('common_if_up_to_500_products'),
          },
        },
        {
          title: t('products_module_two_sub_three_title'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_two_sub_three_list_item_1'),
                  text: t('products_module_two_sub_three_list_item_1_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_2'),
                  text: t('products_module_two_sub_three_list_item_2_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_3'),
                  text: t('products_module_two_sub_three_list_item_3_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_4'),
                  text: t('products_module_two_sub_three_list_item_4_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_5'),
                  text: t('products_module_two_sub_three_list_item_5_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_6'),
                  text: t('products_module_two_sub_three_list_item_6_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_7'),
                  text: t('products_module_two_sub_three_list_item_7_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_8'),
                  text: t('products_module_two_sub_three_list_item_8_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_9'),
                  text: t('products_module_two_sub_three_list_item_9_text'),
                },
                {
                  title: t('products_module_two_sub_three_list_item_10'),
                  text: t('products_module_two_sub_three_list_item_10_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: '49 € / month',
            bottomText: t('common_paid_quarter'),
          },
        },
      ],
    },
    {
      id: 'integrations',
      title: t('products_module_three_title'),
      prevSolutionUrl: '#data-management',
      nextSolutionUrl: '#optimizer',
      submodules: [
        {
          title: t('products_module_three_sub_one_title'),
          text: t('products_module_three_sub_one_text'),
          priceTop: {
            price: 'Included',
            bottomText: 'if up to 5 integrations with suppliers',
          },
          priceMiddle: {
            price: '49 € / month',
            bottomText: 'if up to 10 integrations with suppliers',
          },
          priceBottom: {
            price: 'Request price',
            bottomText: 'for more than 10 integrations',
          },
        },
        {
          title: t('products_module_three_sub_three_title'),
          text: t('products_module_three_sub_three_text'),
          hasAside: true,
          priceTop: {
            price: '35 € / month',
            bottomText: t('common_paid_quarter'),
          },
        },
        {
          title: t('products_module_three_sub_four_title'),
          text: t('products_module_three_sub_four_text'),
          hasAside: true,
          priceTop: {
            price: '9 € / month',
            bottomText: t('common_paid_quarter'),
          },
          footer: (
            <div>
              <p>Instegral supports those payment processing systems</p>
              <LogosWrapper>
                <StaticImage
                  src="../assets/images/mastercard.png"
                  alt="Mastercard."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/visa.png"
                  alt="Visa."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/american.png"
                  alt="American Express."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/sepa.png"
                  alt="SEPA."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/seb.png"
                  alt="SEB."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/kevin.png"
                  alt="Kevin."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/paysera.png"
                  alt="Paysera."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/przelany.png"
                  alt="Przelewy."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
              </LogosWrapper>
            </div>
          ),
        },
        {
          title: t('products_module_three_sub_five_title'),
          text: t('products_module_three_sub_five_text'),
          hasAside: true,
          priceTop: {
            price: '10 € / month',
            bottomText: t('common_paid_quarter'),
          },
        },
        {
          title: t('products_module_three_sub_six_title'),
          text: t('products_module_three_sub_six_text'),
        },
        {
          text: t('products_module_three_sub_seven_text'),
          titleLogo: (
            <StaticImage
              src="../assets/images/ga.png"
              alt="Google Analytics."
              placeholder="blurred"
              quality={100}
              objectFit="contain"
            />
          ),
        },
        {
          title: t('products_module_three_sub_eight_title'),
          text: t('products_module_three_sub_eight_text'),
          footer: (
            <div>
              <p>Instegral supports those payment provides</p>
              <LogosWrapper>
                <StaticImage
                  src="../assets/images/DHL.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/DPD.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/fedex.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/lpexpress.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/omniva.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/ups.png"
                  alt="DHL."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
              </LogosWrapper>
            </div>
          ),
        },
        {
          title: t('products_module_three_sub_ten_title'),
          text: t('products_module_three_sub_ten_text'),
          footer: (
            <div>
              <p>
                Instegral supports the following ERP and accounting systems:
              </p>
              <LogosWrapper>
                <StaticImage
                  src="../assets/images/acumatica.png"
                  alt="acumatica."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/oracle.png"
                  alt="Oracle."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/dynamics.png"
                  alt="MS Dynamics."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/sap.png"
                  alt="SAP."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/deltek.png"
                  alt="Deltek."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/sapone.png"
                  alt="SAP ONE."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
              </LogosWrapper>
            </div>
          ),
        },
        {
          title: t('products_module_three_sub_twelve_title'),
          text: t('products_module_three_sub_twelve_text'),
          footer: (
            <div>
              <p>Instegral supports the following email marketing platforms:</p>
              <LogosWrapper>
                <StaticImage
                  src="../assets/images/mailchimp.png"
                  alt="Mailchimp."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/omnisend.png"
                  alt="Omnisend."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
                <StaticImage
                  src="../assets/images/brevo.png"
                  alt="Brevo."
                  placeholder="blurred"
                  quality={100}
                  objectFit="contain"
                />
              </LogosWrapper>
            </div>
          ),
        },
        {
          text: t('products_module_three_sub_thirteen_text'),
          titleLogo: (
            <StaticImage
              src="../assets/images/fb.png"
              alt="Facebook."
              placeholder="blurred"
              quality={100}
              objectFit="contain"
            />
          ),
        },
      ],
    },
    {
      id: 'optimizer',
      title: t('products_module_four_title'),
      prevSolutionUrl: '#integrations',
      submodules: [
        {
          title: t('products_module_four_sub_one_title'),
          text: t('products_module_four_sub_one_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_one_list_item_1'),
                  text: t('products_module_four_sub_one_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_2'),
                  text: t('products_module_four_sub_one_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_3'),
                  text: t('products_module_four_sub_one_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_4'),
                  text: t('products_module_four_sub_one_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_5'),
                  text: t('products_module_four_sub_one_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_6'),
                  text: t('products_module_four_sub_one_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_7'),
                  text: t('products_module_four_sub_one_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_one_list_item_8'),
                  text: t('products_module_four_sub_one_list_item_8_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: '399 € / month.',
            bottomText: t('common_paid_quarter'),
          },
        },
        {
          title: t('products_module_four_sub_two_title'),
          text: t('products_module_four_sub_two_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_two_list_item_1'),
                  text: t('products_module_four_sub_two_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_2'),
                  text: t('products_module_four_sub_two_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_3'),
                  text: t('products_module_four_sub_two_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_4'),
                  text: t('products_module_four_sub_two_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_5'),
                  text: t('products_module_four_sub_two_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_6'),
                  text: t('products_module_four_sub_two_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_7'),
                  text: t('products_module_four_sub_two_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_8'),
                  text: t('products_module_four_sub_two_list_item_8_text'),
                },
                {
                  title: t('products_module_four_sub_two_list_item_9'),
                  text: t('products_module_four_sub_two_list_item_9_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_three_title'),
          text: t('products_module_four_sub_three_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_three_list_item_1'),
                  text: t('products_module_four_sub_three_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_2'),
                  text: t('products_module_four_sub_three_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_3'),
                  text: t('products_module_four_sub_three_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_4'),
                  text: t('products_module_four_sub_three_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_5'),
                  text: t('products_module_four_sub_three_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_6'),
                  text: t('products_module_four_sub_three_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_7'),
                  text: t('products_module_four_sub_three_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_8'),
                  text: t('products_module_four_sub_three_list_item_8_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_9'),
                  text: t('products_module_four_sub_three_list_item_9_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_10'),
                  text: t('products_module_four_sub_three_list_item_10_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_11'),
                  text: t('products_module_four_sub_three_list_item_11_text'),
                },
                {
                  title: t('products_module_four_sub_three_list_item_12'),
                  text: t('products_module_four_sub_three_list_item_12_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_four_title'),
          text: t('products_module_four_sub_four_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_four_list_item_1'),
                  text: t('products_module_four_sub_four_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_four_list_item_2'),
                  text: t('products_module_four_sub_four_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_four_list_item_3'),
                  text: t('products_module_four_sub_four_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_four_list_item_4'),
                  text: t('products_module_four_sub_four_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_four_list_item_5'),
                  text: t('products_module_four_sub_four_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_four_list_item_6'),
                  text: t('products_module_four_sub_four_list_item_6_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_five_title'),
          text: t('products_module_four_sub_five_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_five_list_item_1'),
                  text: t('products_module_four_sub_five_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_2'),
                  text: t('products_module_four_sub_five_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_3'),
                  text: t('products_module_four_sub_five_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_4'),
                  text: t('products_module_four_sub_five_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_5'),
                  text: t('products_module_four_sub_five_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_6'),
                  text: t('products_module_four_sub_five_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_7'),
                  text: t('products_module_four_sub_five_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_8'),
                  text: t('products_module_four_sub_five_list_item_8_text'),
                },
                {
                  title: t('products_module_four_sub_five_list_item_9'),
                  text: t('products_module_four_sub_five_list_item_9_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_six_title'),
          text: t('products_module_four_sub_six_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_six_list_item_1'),
                  text: t('products_module_four_sub_six_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_2'),
                  text: t('products_module_four_sub_six_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_3'),
                  text: t('products_module_four_sub_six_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_4'),
                  text: t('products_module_four_sub_six_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_5'),
                  text: t('products_module_four_sub_six_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_6'),
                  text: t('products_module_four_sub_six_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_7'),
                  text: t('products_module_four_sub_six_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_six_list_item_8'),
                  text: t('products_module_four_sub_six_list_item_8_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_seven_title'),
          text: t('products_module_four_sub_seven_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_seven_list_item_1'),
                  text: t('products_module_four_sub_seven_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_seven_list_item_2'),
                  text: t('products_module_four_sub_seven_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_seven_list_item_3'),
                  text: t('products_module_four_sub_seven_list_item_3_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_eight_title'),
          text: t('products_module_four_sub_eight_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_eight_list_item_1'),
                  text: t('products_module_four_sub_eight_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_eight_list_item_2'),
                  text: t('products_module_four_sub_eight_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_eight_list_item_3'),
                  text: t('products_module_four_sub_eight_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_eight_list_item_4'),
                  text: t('products_module_four_sub_eight_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_eight_list_item_5'),
                  text: t('products_module_four_sub_eight_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_eight_list_item_6'),
                  text: t('products_module_four_sub_eight_list_item_6_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_nine_title'),
          text: t('products_module_four_sub_nine_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_nine_list_item_1'),
                  text: t('products_module_four_sub_nine_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_nine_list_item_2'),
                  text: t('products_module_four_sub_nine_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_nine_list_item_4'),
                  text: t('products_module_four_sub_nine_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_nine_list_item_6'),
                  text: t('products_module_four_sub_nine_list_item_6_text'),
                },
                {
                  title: t('products_module_four_sub_nine_list_item_7'),
                  text: t('products_module_four_sub_nine_list_item_7_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_ten_title'),
          text: t('products_module_four_sub_ten_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_ten_list_item_1'),
                  text: t('products_module_four_sub_ten_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_ten_list_item_2'),
                  text: t('products_module_four_sub_ten_list_item_2_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_eleven_title'),
          text: t('products_module_four_sub_eleven_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_eleven_list_item_1'),
                  text: t('products_module_four_sub_eleven_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_eleven_list_item_2'),
                  text: t('products_module_four_sub_eleven_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_eleven_list_item_3'),
                  text: t('products_module_four_sub_eleven_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_eleven_list_item_4'),
                  text: t('products_module_four_sub_eleven_list_item_4_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
        {
          title: t('products_module_four_sub_twelve_title'),
          text: t('products_module_four_sub_twelve_text'),
          hasAside: true,
          features: [
            {
              listItems: [
                {
                  title: t('products_module_four_sub_twelve_list_item_1'),
                  text: t('products_module_four_sub_twelve_list_item_1_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_2'),
                  text: t('products_module_four_sub_twelve_list_item_2_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_3'),
                  text: t('products_module_four_sub_twelve_list_item_3_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_4'),
                  text: t('products_module_four_sub_twelve_list_item_4_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_5'),
                  text: t('products_module_four_sub_twelve_list_item_5_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_6'),
                  text: t('products_module_four_sub_twelve_list_item_6_text'),
                },

                {
                  title: t('products_module_four_sub_twelve_list_item_7'),
                  text: t('products_module_four_sub_twelve_list_item_7_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_8'),
                  text: t('products_module_four_sub_twelve_list_item_8_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_9'),
                  text: t('products_module_four_sub_twelve_list_item_9_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_10'),
                  text: t('products_module_four_sub_twelve_list_item_10_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_11'),
                  text: t('products_module_four_sub_twelve_list_item_11_text'),
                },
                {
                  title: t('products_module_four_sub_twelve_list_item_12'),
                  text: t('products_module_four_sub_twelve_list_item_12_text'),
                },
              ],
            },
          ],
          priceTop: {
            price: t('common_included'),
            bottomText: t('common_if_ordered_sales'),
          },
        },
      ],
    },
  ];

  return (
    <Layout>
      <GreyBackground>
        <Content>
          {modules &&
            modules.map((module, id) => (
              <Module
                key={module.id}
                id={module.id}
                text={module.text}
                title={module.title}
                number={id + 1}
                submodules={module.submodules}
                prevSolutionUrl={module.prevSolutionUrl}
                nextSolutionUrl={module.nextSolutionUrl}
              />
            ))}
        </Content>
      </GreyBackground>
    </Layout>
  );
};

export default ProductsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Products | Instegral" />;
