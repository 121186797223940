import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';

import { colors } from 'src/theme/colors';
import { CaretIcon, CheckmarkIcon, MarkIcon } from 'src/assets/icons';
import { device } from 'src/theme/breakpoints';
import Heading, { HeadingVariant } from '../Heading';
import { LinkAnimation } from 'src/utils/animations/animations';
import Modal from 'src/components/Modal';
import Icon from 'src/components/Icon';
import Price from 'src/components/Price';

const UnorderedList = styled.ul<Pick<FeaturesListProps, 'columns'>>`
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${device.laptopSUP} {
    ${({ columns }) =>
      columns &&
      css`
        column-gap: 88px;
        display: grid;
        grid-template-columns: repeat(${columns}, 1fr);
      `}
  }
`;

const ListItem = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: flex-start;
  margin-bottom: 16px;
  position: relative;

  &:hover {
    color: ${colors.purple400};
  }
`;

const CheckmarkIconWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 22px;
  justify-content: center;
  height: 22px;
  margin-right: 14px;
  width: 22px;
`;

const ModuleNumber = styled.span`
  align-items: center;
  background-color: ${colors.purple400};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 18px;
  justify-content: center;
  height: 40px;
  margin-right: 20px;
  width: 40px;
`;

const ModuleHeader = styled.div`
  align-items: center;
  font-size: 20px;
  display: flex;
  margin-bottom: 20px;
  z-index: 1;

  @media ${device.mobileDOWN} {
    font-size: 16px;
  }
`;

const ModalFooter = styled.p`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const NavButton = styled.button`
  ${LinkAnimation};
  align-items: ce;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.purple400};
  display: flex;
  font-weight: 700;
  padding: 0 0 6px;

  &:after {
    background-color: ${colors.purple400};
  }
`;

const StyledCaretIcon = styled(CaretIcon)`
  margin-left: 10px;
`;

const Title = styled.p`
  font-size: 19px;
  font-weight: 700;
  margin: 0;

  @media ${device.desktop} {
    font-size: 24px;
  }

  & + ul {
    margin-top: 46px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoteWrapper = styled.div`
  background-color: ${colors.blue100};
  border-radius: 10px;
  margin: 20px 0 40px;
  padding: 14px;
  font-size: 14px;

  @media ${device.desktop} {
    font-size: 18px;
  }
`;

const PriceWrapper = styled.div`
  margin-top: auto;
`;

const StyledPrice = styled(Price)`
  margin-top: 14px;
`;

export interface FeatureListItemProps {
  title: string;
  text?: string;
}

export interface FeatureProps {
  title?: string;
  price?: string;
  priceBottomText?: string;
  listItems: FeatureListItemProps[];
  isPremium?: boolean;
}
interface FeaturesListProps {
  className?: string;
  columns?: number;
  features: FeatureProps;
  moduleNumber?: number | string;
  moduleTitle?: string;
}

const FeaturesList: FC<FeaturesListProps> = ({
  className,
  columns,
  features,
  moduleNumber,
  moduleTitle,
}) => {
  const [clickedIndex, setClickedIndex] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleItemClick = (e, index) => {
    e.persist();
    setClickedIndex(index);
    setShowModal(true);
  };

  const handleNavigation = () => {
    if (clickedIndex != features.listItems.length - 1) {
      setClickedIndex(clickedIndex + 1);
    } else {
      setClickedIndex(0);
    }
  };

  return (
    <Wrapper>
      {features.title && <Title>{features.title}</Title>}
      {features.isPremium && (
        <NoteWrapper>
          <Icon icon={<MarkIcon />}>
            All standard package features plus the ones below
          </Icon>
        </NoteWrapper>
      )}
      <UnorderedList columns={columns} className={className}>
        {features.listItems.map((item, i) => (
          <ListItem key={i} onClick={e => handleItemClick(e, i)}>
            <CheckmarkIconWrapper>
              <CheckmarkIcon />
            </CheckmarkIconWrapper>
            {item.title}
          </ListItem>
        ))}
      </UnorderedList>
      {features.price && (
        <PriceWrapper>
          <StyledPrice
            bottomText={features.priceBottomText}
            price={features.price}
          />
        </PriceWrapper>
      )}
      <Modal
        isOpen={showModal}
        contentLabel={features.listItems[clickedIndex.toString()].title}
        onClose={() => setShowModal(false)}
      >
        <ModuleHeader>
          <ModuleNumber>#{moduleNumber}</ModuleNumber> {moduleTitle}
        </ModuleHeader>
        <Heading variant={HeadingVariant.CHARLIE}>
          {features.listItems[clickedIndex.toString()].title}
        </Heading>
        <p>{features.listItems[clickedIndex.toString()].text}</p>
        <ModalFooter>
          <NavButton onClick={handleNavigation}>
            <Trans i18nKey="common_next_feature">Next Feature</Trans>
            <StyledCaretIcon />
          </NavButton>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};
export default FeaturesList;
