import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import Submodule, { SubmoduleProps } from 'src/components/Submodule/Submodule';
import { colors } from 'src/theme/colors';
import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import Link, { LinkVariant } from 'src/components/Link';
import { device } from 'src/theme/breakpoints';
import List from 'src/components/List';
import { IListItem } from '../List/List';

const Top = styled.div`
  align-items: center;
  color: ${colors.grey400};
  display: flex;
  font-size: 16px;
`;

const BundleNumber = styled.span`
  align-items: center;
  background-color: ${colors.purple400};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 20px;
  justify-content: center;
  height: 48px;
  margin-left: 12px;
  width: 48px;
`;

const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: relative;
`;

const PrevModule = styled.div`
  bottom: 0;
  border-right: 1px solid ${colors.blue300};
  font-size: 18px;
  left: 0;
  padding: 8px 20px;
  position: absolute;

  @media ${device.mobileDOWN} {
    display: none;
  }
`;

const NextModule = styled.div`
  bottom: 0;
  border-left: 1px solid ${colors.blue300};
  font-size: 18px;
  padding: 8px 20px;
  position: absolute;
  right: 0;

  @media ${device.mobileDOWN} {
    display: none;
  }
`;

const Text = styled.p`
  font-size: 16px;

  @media ${device.laptopUP} {
    margin: 20px 0;
  }
`;

const Section = styled.section`
  color: ${colors.blue700};
  margin-top: 40px;

  @media ${device.laptopUP} {
    &:not(:first-child) {
      margin-top: 118px;
    }
  }
`;

interface ModuleProps {
  id: string;
  text?: string;
  listItemsInText?: IListItem[];
  title: string;
  prevSolutionUrl?: string;
  nextSolutionUrl?: string;
  submodules: SubmoduleProps[];
  number?: string | number;
}

const Module: FC<ModuleProps> = ({
  id,
  listItemsInText,
  nextSolutionUrl,
  number,
  prevSolutionUrl,
  submodules,
  text,
  title,
}) => (
  <Section id={id}>
    <HeadingScope>
      <Header>
        {prevSolutionUrl && (
          <PrevModule>
            <Link variant={LinkVariant.CHARLIE} url={prevSolutionUrl}>
              <Trans i18nKey="common_previous_solution">
                Previous Solution
              </Trans>
            </Link>
          </PrevModule>
        )}
        <HeadingWrapper>
          <Top>
            <Trans i18nKey="common_solution">Solution</Trans>
            <BundleNumber>#{number}</BundleNumber>
          </Top>
          <Heading variant={HeadingVariant.FOXTROT}>{title}</Heading>
        </HeadingWrapper>
        {nextSolutionUrl && (
          <NextModule>
            <Link variant={LinkVariant.ALFA} url={nextSolutionUrl}>
              <Trans i18nKey="common_next_solution">Next Solution</Trans>
            </Link>
          </NextModule>
        )}
      </Header>
      {text && <Text>{text}</Text>}
      {listItemsInText && <List items={listItemsInText} columns={3} />}
      {submodules.map((submodule, idx) => (
        <Submodule
          key={idx}
          title={submodule.title}
          text={submodule.text}
          features={submodule.features}
          moduleNumber={number}
          moduleTitle={title}
          number={`${number}.${idx + 1}`}
          priceTop={submodule.priceTop}
          priceMiddle={submodule.priceMiddle}
          priceBottom={submodule.priceBottom}
          priceFooterText={submodule.priceFooterText}
          hasAside={submodule.hasAside}
          footer={submodule.footer}
          titleLogo={submodule.titleLogo}
        />
      ))}
    </HeadingScope>
  </Section>
);

export default Module;
